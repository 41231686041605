import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Highlight } from 'ui-kit/typography';
import { RecipeCard } from 'ui-kit/card';
import Container from 'ui-kit/container';
import Filter from 'ui-kit/filter';
import Intro from 'ui-kit/intro';
import Section from 'ui-kit/section';
import Stage from 'ui-kit/stage';
import { log } from 'helpers/utils';

import Layout from '../components/layout';

const filters = [
    {
        name: 'Suche',
        type: 'search',
        filterFn: value => ({ title, ingredients }) => {
            // Leere Suchen und Suchen mit weniger als 3 Buchstabend ignorieren
            if (value === '' || value.length < 3) {
                return true;
            }

            const search = value.toLowerCase();

            return (
                title.toLowerCase().includes(search) ||
                ingredients
                    .reduce(
                        (acc, current) =>
                            `${acc}|${current.items
                                .map(({ title: ingredientTitle }) => ingredientTitle)
                                .join('|')
                                .toLowerCase()}`,
                        ''
                    )
                    .includes(search)
            );
        },
    },
    {
        name: 'Titel',
        type: 'text',
        value: <>Rezepte filtern:</>,
    },
    {
        name: 'Kategorie',
        type: 'select',
        filterFn: value => ({ category }) => value === '*' || category === value,
        options: [
            { name: 'Kategorie', value: '*' },
            { name: 'Huhn', value: 'Huhn' },
            { name: 'Lamm', value: 'Lamm' },
            { name: 'Rind', value: 'Rind' },
            { name: 'Schwein', value: 'Schwein' },
            { name: 'Wurst', value: 'Wurst' },
        ],
    },
    {
        name: 'Schwierigkeit',
        type: 'select',
        filterFn: value => ({ difficulty }) => value === '*' || difficulty === parseInt(value, 10),
        options: [
            { name: 'Schwierigkeit', value: '*' },
            { name: 'einfach', value: 1 },
            { name: 'mittel', value: 2 },
            { name: 'schwierig', value: 3 },
        ],
    },
    {
        name: 'Zubereitungsdauer',
        type: 'select',
        filterFn: value => ({ time }) => {
            if (value === '*') {
                return true;
            }
            switch (value) {
                case '10 - 30':
                    return time >= 10 && time <= 30;

                case '30 - 60':
                    return time >= 30 && time <= 60;

                case '60 - 90':
                    return time >= 60 && time <= 90;

                default:
                    log(`Zubereitungszeit Filter: value "${time}" is not defined.`);
                    return false;
            }
        },
        options: [
            { name: 'Zubereitungsdauer', value: '*' },
            { name: '10 - 30 Minuten', value: '10 - 30' },
            { name: '30 - 60 Minuten ', value: '30 - 60' },
            { name: '60 - 90 Minuten', value: '60 - 90' },
        ],
    },
];

const RecipesPage = ({
    data: {
        allRecipesJson: { edges: data },
    },
}) =>
    (recipes => {
        const randomRecipe = Math.floor(Math.random() * recipes.length - 1);
        return (
            <Layout title="Rezepte" description="Rezepte">
                <Stage
                    image={recipes[randomRecipe].image}
                    imageAlt={`Angerichtes Gericht für das Rezept ${recipes[randomRecipe].title}`}
                >
                    <RecipeCard
                        difficulty={recipes[randomRecipe].difficulty}
                        time={recipes[randomRecipe].time}
                        tag="Rezept des Tages"
                        title={recipes[randomRecipe].title}
                        button={{ text: 'Jetzt nachkochen' }}
                        hero
                        transparentBg
                        link={`/rezepte/${recipes[randomRecipe].slug}`}
                    />
                </Stage>
                <Section bg="pattern" container="l">
                    <Intro title="Suchen Sie sich was Leckeres aus!" gap="xxxl">
                        Sie kochen gerne und haben Freude am Genießen? Dann lassen Sie sich von
                        unseren Rezeptvorschlägen inspirieren und kochen Ihr Wunschgericht am besten
                        gleich nach. Und wenn Sie bei der Zubereitung unsere Produkte verwenden,
                        dann wird der Genuss perfekt.
                        <Highlight>Viel Spaß beim Probieren und Genießen!</Highlight>
                    </Intro>

                    <Filter
                        mode="XOR"
                        items={recipes}
                        filters={filters}
                        onUpdate={items => log('filtered', items)}
                        gap="xxl"
                        render={items =>
                            items.length <= 0 ? (
                                'Kein passendes Rezept gefunden'
                            ) : (
                                <Container grid cols={3}>
                                    {items.map(item => (
                                        <RecipeCard
                                            key={`recipe-${item.title}`}
                                            gap={{ small: 'xxl', medium: 'none' }}
                                            tag={item.category}
                                            link={`/rezepte/${item.slug}`}
                                            {...item}
                                        />
                                    ))}
                                </Container>
                            )
                        }
                    />
                </Section>
            </Layout>
        );
    })(
        data.map(item => ({
            ...item.node,
            image: item.node.image ? item.node.image.childImageSharp : null,
        }))
    );

RecipesPage.propTypes = {
    data: PropTypes.shape({
        allRecipesJson: PropTypes.shape({
            edges: PropTypes.arrayOf(PropTypes.object),
        }),
    }).isRequired,
};

export const query = graphql`
    query RecipeQuery {
        allRecipesJson {
            edges {
                node {
                    id
                    title
                    slug
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1920, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    category
                    time
                    difficulty
                    portions
                    ingredients {
                        title
                        items {
                            amount
                            unit
                            title
                        }
                    }
                    steps
                }
            }
        }
    }
`;

export default RecipesPage;
